import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Smile Stylist Featured In The Oakville Club Magazine" 
    description="It’s a proud moment when your community recognizes your efforts.  Check out this feature in the Oakville Club Magazine regarding our excellence in cosmetic dentistry." 
    keywords="best smiles, dentist blog, teeth canada, dental clinics, oakville, toronto, mississauga, canada" />
    <main className="site-content prindex" role="main">
    <section id="main-top-img" className="news-press hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <div className="pr__cont">
              <h1>Check Out Our Smile Stylist Featured In The Oakville Club Magazine!</h1>
              <h2>Dr. Kaloti was recently featured in The Oakville Club magazine.</h2>
            </div>

          </div>
        </div>
      </div>
    </section>
    <section id="main-top-img" className="news-press hidden-sm hidden-md hidden-lg">
      <div className="container">
        <div className="row">
          <p>&nbsp;</p>
        </div>
      </div>
    </section>
    <section id="top-mobile" className="hidden-sm hidden-md hidden-lg">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h2>Check Out Our Smile Stylist Featured In The Oakville Club Magazine!</h2>
            <h2>Dr. Kaloti was recently featured in The Oakville Club magazine.</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <h2>The Oakville Club is...</h2>
            <p className="lead">An exclusive members-only club for families, singles and couples, located on the beautiful Oakville waterfront on Water Street.</p>
            <p>Here’s some of what the club’s magazine had to say about our Smile Stylist:
              “Dr. Hisham Kaloti is a dental artist who relies on conservative procedure to enhance the Health, Function, Beauty and Rejuvenation of smiles. His expertise in blending the science of dentistry with his artistic ability allows him to create natural appearing smiles on hundreds of people from all over the world so they can look and feel their best.”
              Much thanks, The Oakville Club!</p>
          </div>
          <div className="col-sm-4 blog-right">
            <img alt="Oakville Club Magazine" src={ require("../../images/desktop/_pressrelease/pics/oc-side-image-blog.jpg" )} className="img-responsive wow animated zoomIn" data-wow-delay="0.6s" />
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage